import React from 'react'
import Scene from './Scene'


export default function Main() {
    return (
        <>
            <div className='w-full h-[100vh] overflow-hidden m-0'>
                <Scene />
            </div>
        </>
    )
}
