import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Canvas, useLoader, useFrame } from '@react-three/fiber'
import { PerspectiveCamera, OrbitControls, MeshReflectorMaterial, CubeCamera, Environment, Effects } from '@react-three/drei'
import { LinearEncoding, RepeatWrapping, TextureLoader } from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Mesh, Color, Vector3 } from "three";
import * as THREE from 'three'
import { EffectComposer, DepthOfField, Bloom } from '@react-three/postprocessing'

//SpotLightHelper, useHelper

export default function Scene() {

    return (
        <>
            <Suspense fallback={null}>
                <Canvas>
                    <Content />
                    {/*https://docs.pmnd.rs/react-postprocessing/effects/god-rays*/}
                    <EffectComposer>
                        <DepthOfField focusDistance={0} focalLength={0.2} bokehScale={2} height={500} />
                        <Bloom luminanceThreshold={0} luminanceSmoothing={0.95} height={500} />
                    </EffectComposer>
                </Canvas>
            </Suspense>
        </>
    )
}

function Content() {

    return (
        <>
            {/*fov — 攝像機視錐體垂直視野角度, aspect — 攝像機視錐體長寬比, near — 攝像機視錐體近端面, far — 攝像機視錐體遠端面*/}
            <PerspectiveCamera makeDefault fov={90} position={[3, 2, 5]} />
            {/*https://threejs.org/docs/?q=OrbitControls#examples/zh/controls/OrbitControls*/}
            {/*垂直旋轉的角度的上限，範圍是0到Math.PI*/}
            <OrbitControls maxPolarAngle={1.5} target={[0, 0, 0]} />
            <Ground />
            <SpotLight />
            <CubeCamera frames={Infinity} resolution={256}>
                {(texture) => (
                    <>
                        <Environment map={texture} />
                        <Car />
                    </>
                )}
            </CubeCamera>
            <Geoes />
            <Rings />
            <color args={[0, 0, 0]} attach="background" />
        </>
    )
}

function Geoes() {
    return (
        <>
            {Array(50).fill().map((element, i) => (
                <Geo key={i} colorCode={(i % 2 === 0 ? [1.5, 0.5, 1] : [0, 0.5, 1])} geoType={Math.round(Math.random())} />
            ))}
        </>
    )
}

function Geo({ colorCode, geoType }) {
    const boxRef = useRef()

    const [hover, setHover] = useState(false)

    const handleOnHover = () => {
        setHover(true)
    }

    const handleOnLeave = () => {
        setHover(false)
    }

    const [rotationx] = useState(Math.round(Math.random()) * 2 - 1)
    const [rotationy] = useState(Math.round(Math.random()) * 2 - 1)
    const [rotationz] = useState(Math.round(Math.random()) * 2 - 1)

    let random = Math.random() + 0.1
    const [scale, setScale] = useState(random)
    const [randomScale] = useState(random)

    const [boxPosition, setBoxPosition] = useState(getPosition())

    function getPosition() {
        let vector = new Vector3((Math.random() * 2 - 1) * 3, Math.random() * 3 + 0.5, (Math.random() * 2 - 1) * 20);
        if (vector.x < 0) vector.x -= 2;
        if (vector.x > 0) vector.x += 2;
        return vector;
    }

    function reset() {
        let vector = new Vector3((Math.random() * 2 - 1) * 3, Math.random() * 3 + 0.5, (Math.random() * 2 - 1) * 20 + 10);
        if (vector.x < 0) vector.x -= 2;
        if (vector.x > 0) vector.x += 2;
        return vector;
    }

    useFrame((state, delta) => {
        let mesh = boxRef.current;
        mesh.position.z -= delta
        if (mesh.position.z < -20) {
            setBoxPosition(reset())
        }
        mesh.rotation.x += 0.01 * rotationx
        mesh.rotation.y += 0.01 * rotationy
        mesh.rotation.z += 0.01 * rotationz

        if (hover === true) {
            if (scale < 1.25) {
                let newScale = scale + 0.01
                setScale(newScale)
            }
        }
        else {
            if (scale > randomScale) {
                let newScale = scale - 0.01
                setScale(newScale)
            }
        }
    })

    return (
        <>
            <mesh
                onPointerEnter={handleOnHover}
                onPointerLeave={handleOnLeave}
                scale={scale}
                ref={boxRef}
                castShadow
                receiveShadow
                position={boxPosition}>
                {(geoType === 0 ? <torusGeometry args={[0.5, 0.25, 8, 100]} /> : <boxGeometry args={[1, 1, 1]} />)}
                <meshStandardMaterial color={colorCode} envMapIntensity={1} />
            </mesh>
        </>
    )
}

function Ground() {
    const [texture1, texture2] = useLoader(TextureLoader, [
        process.env.PUBLIC_URL + './img/texture/aerial_asphalt_01_rough_2k.jpg', //img download from https://polyhaven.com/
        process.env.PUBLIC_URL + './img/texture/rough_plasterbrick_05_rough_2k.jpg',
    ])

    useEffect(() => {
        [texture1, texture2].forEach((texture) => {
            texture.wrapS = RepeatWrapping //紋理貼圖在水平方向上將如何包裹
            texture.wrapT = RepeatWrapping //紋理貼圖在垂直方向上將如何包裹
            texture.repeat.set(10, 10)
            texture2.encoding = LinearEncoding //LinearEncoding默認值
        })
    }, [texture1, texture2])

    useFrame((state) => {
        let elapsed = state.clock.getElapsedTime();
        let t = elapsed * -0.2;
        texture2.offset.set(0, t % 1);
        texture1.offset.set(0, t % 1);
    });

    return (
        <>
            {/*rotate the plane mesh to 90 deg*/}
            <mesh rotation-x={-Math.PI * 0.5} castShadow receiveShadow>
                <planeGeometry args={[50, 50, 1, 1]} />
                <MeshReflectorMaterial
                    normalMap={texture1}
                    roughnessMap={texture2}
                    dithering={true}
                    color={[0.01, 0.01, 0.01]}
                    roughness={1}
                    blur={[999, 999]}
                    mixBlur={99} //模糊強度
                    mixStrength={99} //反射強度
                    mixContrast={1} //反射對比度
                    resolution={1024} //分辨率
                    mirror={0}
                    depthScale={0.01}
                    minDepthThreshold={0.9}
                    maxDepthThreshold={1}
                    depthToBlurRatioBias={0.25}
                    debug={0}
                    reflectorOffset={0.2}
                />
            </mesh>
        </>
    )
}

function SpotLight() {
    const redSpotLight = useRef()
    const blueSpotLight = useRef()
    /* useHelper(redSpotLight, SpotLightHelper, 'red')
    useHelper(blueSpotLight, SpotLightHelper, 'blue') */

    return (
        <>
            <spotLight
                color={[1.5, 0.5, 1]}
                intensity={1.5} //光照強度
                angle={0.7} //光線散射角度，最大為Math.PI/2
                penumbra={0.5} //聚光錐的半影衰減百分比。在0和1之間的值。默認為0。
                position={[5, 5, 0]}
                castShadow
                ref={redSpotLight}
            />
            <spotLight
                color={[0, 0.5, 1]}
                intensity={2}
                angle={0.7}
                penumbra={0.5}
                position={[-5, 5, 0]}
                castShadow
                ref={blueSpotLight}
            />
        </>
    )
}

function Car() {
    const gltf = useLoader(
        GLTFLoader,
        process.env.PUBLIC_URL + './modal/tesla/scene.gltf'
    );

    useEffect(() => {
        gltf.scene.scale.set(0.01, 0.01, 0.01);
        gltf.scene.position.set(0, 0.75, 0);
        gltf.scene.rotation.set(0, Math.PI, 0)
        gltf.scene.traverse((object) => {
            if (object instanceof Mesh) { //check callback is a mesh
                object.castShadow = true;
                object.receiveShadow = true;
                object.material.envMapIntensity = 10
            }
        });
    }, [gltf]);

    useFrame((state) => {
        let elapsed = state.clock.getElapsedTime();
        //components show at children x 4 
        let frontWheel = gltf.scene.children[0].children[0].children[0].children[0].children[7].children[0]
        let backWheel = gltf.scene.children[0].children[0].children[0].children[0].children[7].children[1]
        let speed = (velocity === true ? -20 : -2)

        frontWheel.children[0].rotation.x = elapsed * speed
        frontWheel.children[1].rotation.x = elapsed * speed
        frontWheel.children[2].rotation.x = elapsed * speed
        frontWheel.children[3].rotation.x = elapsed * speed
        frontWheel.children[4].rotation.x = elapsed * speed
        frontWheel.children[5].rotation.x = elapsed * speed
        frontWheel.children[6].rotation.x = elapsed * speed

        backWheel.children[0].rotation.x = elapsed * speed
        backWheel.children[1].rotation.x = elapsed * speed
        backWheel.children[2].rotation.x = elapsed * speed
        backWheel.children[3].rotation.x = elapsed * speed
        backWheel.children[4].rotation.x = elapsed * speed
        backWheel.children[5].rotation.x = elapsed * speed
        backWheel.children[6].rotation.x = elapsed * speed
    })

    const carRef = useRef()
    const [clicked, setClicked] = useState(false)
    const [velocity, setVelocity] = useState(false)
    const handleOnClick = () => {
        setClicked(clicked => !clicked)
        setVelocity(velocity => !velocity)
    }

    return (
        <>
            {clicked &&
                <Effects>
                    <shaderPass args={[VolumetricLightShader]} needsSwap={false} />
                </Effects>}

            <mesh ref={carRef} onClick={handleOnClick}>
                <primitive object={gltf.scene} />
            </mesh>
        </>
    )
}

function Rings() {
    const itemsRef = useRef([]);

    useFrame((state) => {
        let elapsed = state.clock.getElapsedTime();

        for (let i = 0; i < itemsRef.current.length; i++) {
            let mesh = itemsRef.current[i];
            let z = (i - 7) * 3.5 + ((elapsed * 0.4) % 3.5) * 2;
            let dist = Math.abs(z);
            mesh.position.set(0, 0, -z);
            mesh.scale.set(1 - dist * 0.04, 1 - dist * 0.04, 1 - dist * 0.04);

            let colorScale = 1;
            if (dist > 2) {
                colorScale = 1 - (Math.min(dist, 12) - 2) / 10;
            }
            colorScale *= 0.5;

            if (i % 2 === 1) {
                mesh.material.emissive = new Color(6, 0.15, 0.7).multiplyScalar(colorScale);
            } else {
                mesh.material.emissive = new Color(0.1, 0.7, 3).multiplyScalar(colorScale);
            }
        }
    });

    return (
        <>
            {Array(15).fill().map((v, i) => (
                <mesh
                    castShadow
                    receiveShadow
                    position={[0, 0, 0]}
                    key={i}
                    ref={(el) => (itemsRef.current[i] = el)}
                >
                    {/* radius - 半徑，默認值是1, tube — 管道的半徑，默認值為0.4, radialSegments — 管道橫截面的分段數，默認值為8, tubularSegments — 管道的分段數，默認值為6 */}
                    <torusGeometry args={[4, 0.05, 16, 100]} />
                    <meshStandardMaterial emissive={[4, 0.1, 0.4]} color={[0, 0, 0]} />
                </mesh>
            ))}
        </>
    )
}

const VolumetricLightShader = {
    uniforms: {
        tDiffuse: { value: null },
        lightPosition: { value: new THREE.Vector2(0.5, 0.75) },
        exposure: { value: 0.3 },
        decay: { value: 0.95 },
        density: { value: 0.4 },
        weight: { value: 0.3 },
        samples: { value: 50 }
    },
    vertexShader: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }`,
    fragmentShader: `
    varying vec2 vUv;
    uniform sampler2D tDiffuse;
    uniform vec2 lightPosition;
    uniform float exposure;
    uniform float decay;
    uniform float density;
    uniform float weight;
    uniform int samples;
    const int MAX_SAMPLES = 100;
    void main()
    {
      vec2 texCoord = vUv;
      vec2 deltaTextCoord = texCoord - lightPosition;
      deltaTextCoord *= 1.0 / float(samples) * density;
      vec4 color = texture2D(tDiffuse, texCoord);
      float illuminationDecay = 1.0;
      for(int i=0; i < MAX_SAMPLES; i++) {
        if(i == samples) break;
        texCoord -= deltaTextCoord;
        vec4 tex = texture2D(tDiffuse, texCoord);
        tex *= illuminationDecay * weight;
        color += tex;
        illuminationDecay *= decay;
      }
      gl_FragColor = color * exposure;
    }`
}


